'use client';

import { useRouter } from 'next/navigation';

const BaseErrorBoundary: React.FC<{
  callback?: () => void;
}> = ({ callback }) => {
  const router = useRouter();
  return (
    <div className="h-full w-full flex justify-center items-center bg-shades-base">
      <div className="flex flex-col -mt-[15vh] justify-center items-center">
        <h1 className="text-xl tracking-[0.2em] font-semibold mb-2 text-colors-red">
          ERROR
        </h1>
        <h2 className="text-3xl font-serif">Something went wrong!</h2>
        <p className="opacity-70 mt-1">
          {"We're sorry, we'll try to get this fixed soon."}
        </p>
        {callback ? (
          <button
            onClick={
              () => callback() // Attempt to recover by trying to re-render the segment
            }
            className="bg-colors-green rounded-md p-2 px-4 mt-4 text-shades-base  font-semibold hover:brightness-110"
          >
            Try again
          </button>
        ) : (
          <button
            onClick={() => router.push('/')}
            className="bg-colors-green rounded-md p-2 px-4 mt-4 text-shades-base  font-semibold hover:brightness-110"
          >
            Go Home
          </button>
        )}
      </div>
    </div>
  );
};

export default BaseErrorBoundary;
